import { GetRequest, PostRequest, successParam } from '@/api/base';

const apiUrl = {
    addSubUser: 'v3/web/community/subUser/add',
    editSubUser: 'v3/web/community/subUser/edit',
    deleteSubUser: 'v3/web/community/subUser/delete',
    deleteMainUser: 'v3/web/community/mainUser/delete',
    addBuild: 'v3/web/community/build/add',
    editBuild: 'v3/web/community/build/edit',
    delBuild: 'v3/web/community/build/delete',
    resetPasswd: 'v3/web/community/user/resetPwd',
    thirdPartCameraGetListForCommunity: 'v3/web/community/thirdPartCamera/getListForIns',
    thirdPartCameraGetLinkDeviceList: 'v3/web/community/thirdPartCamera/getLinkDeviceList',
    thirdPartCameraDelete: 'v3/web/community/thirdPartCamera/delete',
    thirdPartCameraAdd: 'v3/web/community/thirdPartCamera/add',
    thirdPartCameraEdit: 'v3/web/community/thirdPartCamera/edit',
    thirdPartCameraGetListForOwner: 'v3/web/community/thirdPartCamera/getListForOwner'

};

class ResidentDeviceApi {
    addSubUser(params: object, callback: successParam) {
        PostRequest(apiUrl.addSubUser, params, callback);
    }

    editSubUser(params: object, callback: successParam) {
        PostRequest(apiUrl.editSubUser, params, callback);
    }

    deleteSubUser(params: object, callback: successParam) {
        PostRequest(apiUrl.deleteSubUser, params, callback);
    }

    deleteMainUser(params: object, callback: successParam) {
        PostRequest(apiUrl.deleteMainUser, params, callback);
    }

    addBuild(params: object, callback: successParam) {
        PostRequest(apiUrl.addBuild, params, callback);
    }

    editBuild(params: object, callback: successParam) {
        PostRequest(apiUrl.editBuild, params, callback);
    }

    delBuild(params: object, callback: successParam) {
        PostRequest(apiUrl.delBuild, params, callback);
    }

    resetPasswd(params: object, callback: successParam) {
        PostRequest(apiUrl.resetPasswd, params, callback);
    }

    thirdPartCameraGetListForCommunity(params: object, callback: Function) {
        GetRequest(apiUrl.thirdPartCameraGetListForCommunity, params, callback);
    }

    thirdPartCameraAdd(params: object, callback: successParam) {
        PostRequest(apiUrl.thirdPartCameraAdd, params, callback);
    }

    thirdPartCameraEdit(params: object, callback: successParam) {
        PostRequest(apiUrl.thirdPartCameraEdit, params, callback);
    }

    thirdPartCameraGetLinkDeviceList(params: object, callback: Function) {
        GetRequest(apiUrl.thirdPartCameraGetLinkDeviceList, params, callback);
    }

    thirdPartCameraDelete(params: object, callback: successParam) {
        PostRequest(apiUrl.thirdPartCameraDelete, params, callback);
    }

    thirdPartCameraGetListForOwner(params: object, callback: Function) {
        GetRequest(apiUrl.thirdPartCameraGetListForOwner, params, callback);
    }

    resetRoom(params: object, callback: successParam) {
        PostRequest('v3/web/community/room/reset', params, callback);
    }
}

const residentDeviceApi = new ResidentDeviceApi();
export default residentDeviceApi;