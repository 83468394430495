import { GetRequest, PostRequest, successParam } from '@/api/base';

const apiUrl = {
    getCommunityBuildRoomUser: 'v3/web/community/user/getRoomResidentForPm',
    addResidentPin: 'v3/web/community/key/addUserPinForOld',
    editResidentPin: 'v3/web/community/key/editUserPinForOld',
    addResidentRfCard: 'v3/web/community/key/addUserRfCardForOld',
    editResidentRfCard: 'v3/web/community/key/editUserRfCardForOld',
    importRfCard: 'v3/web/community/key/importUserRfCardForOld',
    deleteAllRfCard: 'v3/web/community/key/delUserAllRfCardForOld',
    deleteAllFace: 'v3/web/community/face/delAllForOld'
};

class ResidentAuthApi {
    getCommunityBuildRoomUser(callback: Function) {
        GetRequest(apiUrl.getCommunityBuildRoomUser, {}, callback);
    }

    addResidentPin(params: object, callback: successParam) {
        PostRequest(apiUrl.addResidentPin, params, callback);
    }

    editResidentPin(params: object, callback: successParam) {
        PostRequest(apiUrl.editResidentPin, params, callback);
    }

    addResidentRfCard(params: object, callback: successParam) {
        PostRequest(apiUrl.addResidentRfCard, params, callback);
    }

    editResidentRfCard(params: object, callback: successParam) {
        PostRequest(apiUrl.editResidentRfCard, params, callback);
    }

    importRfCard(params: object, callback: successParam) {
        PostRequest(apiUrl.importRfCard, params, callback);
    }

    deleteAllRfCard(params: {PassWd: string;Type: string}, callback: successParam) {
        PostRequest(apiUrl.deleteAllRfCard, params, callback);
    }

    deleteAllFace(params: {PassWd: string;Type: string}, callback: successParam) {
        PostRequest(apiUrl.deleteAllFace, params, callback);
    }
}

const residentAuthApi = new ResidentAuthApi();

export default residentAuthApi;
