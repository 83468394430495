import { GetRequest, PostRequest, successParam } from '@/api/base';

const apiUrl = {
    addMainUser: 'v3/web/single/mainUser/add',
    editMainUser: 'v3/web/single/mainUser/edit',
    addSubUser: 'v3/web/single/subUser/add',
    editSubUser: 'v3/web/single/subUser/edit',
    resetPassword: 'v3/web/single/user/resetPwd',
    getSmartHomeUri: 'v3/web/single/user/getSmartHomeUri'
};

class UserForSingleApi {
    addMainUser(params: object, callback: successParam) {
        PostRequest(apiUrl.addMainUser, params, callback);
    }

    editMainUser(params: object, callback: successParam) {
        PostRequest(apiUrl.editMainUser, params, callback);
    }

    addSubUser(params: object, callback: successParam) {
        PostRequest(apiUrl.addSubUser, params, callback);
    }

    editSubUser(params: object, callback: successParam) {
        PostRequest(apiUrl.editSubUser, params, callback);
    }

    resetPassword(params: object, callback: successParam) {
        PostRequest(apiUrl.resetPassword, params, callback);
    }

    getSmartHomeUri(params: { ID: string }, callback: Function) {
        PostRequest(apiUrl.getSmartHomeUri, params, callback, () => ({}), false);
    }

    resetRoom(params: object, callback: successParam) {
        PostRequest('v3/web/single/room/reset', params, callback);
    }
}

const userForSingleApi = new UserForSingleApi();
export default userForSingleApi;
