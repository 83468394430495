import { PostRequest, successParam } from '@/api/base';

const apiUrl = {
    editApartment: 'v3/web/community/room/editForPm'
};

class ApartmentForPMApi {
    editApartment(params: object, callback: successParam) {
        PostRequest(apiUrl.editApartment, params, callback);
    }
}

const apartmentForPMApi = new ApartmentForPMApi();
export default apartmentForPMApi;