import {
    PostRequest, successParam, errorParam, GetRequest
} from '@/api/base';
import { GetPmAppStatus, ResetPmPasswd, ChangeComPMParams } from './all.type';

const apiUrl = {
    changePmAppStatus: 'v3/web/common/account/changePmAppStatus',
    resetPmPasswd: 'v3/web/common/account/resetPmAppPwd',
    changeComPerManage: 'v3/web/common/account/changeComPerManage',
    addRoom: 'v3/web/community/room/add',
    editRoom: 'v3/web/community/room/edit',
    deleteCommunity: 'v3/web/community/communityData/delete',
    getSmartHomeUri: 'v3/web/community/communityData/getSmartHomeUri'
};

class CommunityApi {
    changePmAppStatus(params: GetPmAppStatus, callback: successParam, errorCallBack: errorParam) {
        PostRequest(apiUrl.changePmAppStatus, params, callback, errorCallBack);
    }

    resetPmPasswd(params: ResetPmPasswd, callback: successParam) {
        PostRequest(apiUrl.resetPmPasswd, params, callback);
    }

    changeComPerManage(params: ChangeComPMParams, callback: successParam) {
        PostRequest(apiUrl.changeComPerManage, params, callback);
    }

    addRoom(params: object, callback: successParam) {
        PostRequest(apiUrl.addRoom, params, callback);
    }

    editRoom(params: object, callback: successParam) {
        PostRequest(apiUrl.editRoom, params, callback);
    }

    deleteCommunity(params: object, callback: successParam) {
        PostRequest(apiUrl.deleteCommunity, params, callback);
    }

    getSmartHomeUri(callback: Function) {
        PostRequest(apiUrl.getSmartHomeUri, {}, callback, () => ({}), false);
    }
}

const communityApi = new CommunityApi();
export default communityApi;
