import { GetRequest } from '@/api/base';

const apiUrl = {
    getExportTime: 'v3/web/common/log/getExportTime'
};

class LogForPmApi {
    getExportTime(params: object, callback: Function) {
        GetRequest(apiUrl.getExportTime, params, callback);
    }
}

const logForPmApi = new LogForPmApi();
export default logForPmApi;
