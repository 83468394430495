import { GetRequest } from '@/api/base';

const apiUrl = {
    getLogList: 'v3/web/community/alarm/getLogList',
    getOfficeArmingList: 'v3/web/office/alarm/getArmingList',
    getCommunityArmingList: 'v3/web/community/alarm/getArmingList'
};

class AlarmRecordsApi {
    getLogList(params: object, callback: Function) {
        GetRequest(apiUrl.getLogList, params, callback);
    }

    getOfficeArmingList(params: object, callback: Function) {
        GetRequest(apiUrl.getOfficeArmingList, params, callback);
    }

    getCommunityArmingList(params: object, callback: Function) {
        GetRequest(apiUrl.getCommunityArmingList, params, callback);
    }
}

const alarmRecordsApi = new AlarmRecordsApi();
export default alarmRecordsApi;
