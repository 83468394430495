import { PostRequest, successParam, GetRequest } from '@/api/base';
import {
    GetUpdateBillType, ActiveAccountType, GetUserBillingParams, GetPMUserInfoParams
} from './all.type';

const apiUrl = {
    importKit: 'v3/web/single/kit/import',
    updateBillingList: 'v3/web/common/account/updateBillList',
    activeAccount: 'v3/web/common/account/activeAccount',
    getUserBilling: 'v3/web/common/account/getBilling',
    getPMUserInfo: 'v3/web/common/account/getPmInfoForSuper',
    getAllInstallerCommunity: 'v3/web/common/manage/getAllInstaller'
};

class UserForSuperApi {
    importKit(params: object, callback: successParam, errorCallback: Function) {
        PostRequest(apiUrl.importKit, params, callback, errorCallback);
    }

    updateBillingList(params: GetUpdateBillType, callback: successParam) {
        PostRequest(apiUrl.updateBillingList, params, callback);
    }

    activeAccount(params: ActiveAccountType, callback: successParam) {
        PostRequest(apiUrl.activeAccount, params, callback);
    }

    getUserBilling(params: GetUserBillingParams, callback: Function) {
        GetRequest(apiUrl.getUserBilling, params, callback);
    }

    getPMUserInfo(params: GetPMUserInfoParams, callback: Function) {
        GetRequest(apiUrl.getPMUserInfo, params, callback);
    }

    getAllInstallerCommunity(callback: Function) {
        GetRequest(apiUrl.getAllInstallerCommunity, {}, callback);
    }
}
const userForSuperApi = new UserForSuperApi();
export default userForSuperApi;
