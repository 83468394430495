import { GetRequest, PostRequest, successParam } from '@/api/base';
import HttpRequest from '@/util/axios.config';
import * as commonType from './all.type';

const apiUrl = {
    getShowFeaturePlan: 'v3/web/community/featurePlan/getShowFeaturePlan',
    getFeaturePlanFee: 'v3/web/community/featurePlan/getFeaturePlanFee',
    getComputedPrice: 'v3/web/community/order/computedPriceForDay',
    createSubscriptionByDay: 'v3/community/web/createSubscriptionByDay',
    getCommunityList: 'v3/web/community/project/getAll',
    getOfficeList: 'v3/web/office/project/getOfficeList',
    getCommunitySubUserPay: 'v3/web/community/user/getSubPayInfo',
    getSingleSubUserPay: 'v3/web/single/user/getSubPayInfo',
    getPMUserChargePlan: 'v3/web/community/user/getChargeInfo'
};

class SubscriptionApi {
    getShowFeaturePlan(params: commonType.GetShowFeaturePlanParams | {}, callback: Function) {
        GetRequest(apiUrl.getShowFeaturePlan, params, callback, false);
    }

    getFeaturePlanFee(params: object, callback: Function) {
        GetRequest(apiUrl.getFeaturePlanFee, params, callback, false);
    }

    getComputedPrice(params: object, callback: successParam) {
        PostRequest(apiUrl.getComputedPrice, params, callback);
    }

    downInvoice(orderID: string, url: string, Payer?: string) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', `${HttpRequest.host}web-server/${url}?ID=${orderID}${Payer ? `&Payer=${Payer}` : ''}`);
        xhr.setRequestHeader('x-auth-token', localStorage.getItem('token') as string);
        xhr.setRequestHeader('x-community-id', localStorage.getItem('communityID') as string);
        xhr.setRequestHeader('x-cloud-version', HttpRequest.version);
        xhr.send();
        // 返回类型blob
        xhr.responseType = 'blob';
        xhr.onload = function () {
            if (this.status === 200) {
                const blob = this.response;
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = function (e) {
                    // 转换完成，创建一个a标签用于下载
                    const a = document.createElement('a');
                    let filename = (xhr.getResponseHeader('Content-Disposition') as string).split('; ')[1].split('=')[1];
                    filename = filename.replace(/"/g, '');
                    a.download = filename;
                    a.href = (e.target!.result as string);
                    document.body.append(a);
                    a.click();
                    a.remove();
                };
            }
        };
    }

    getCommunityList(params: object, callback: Function) {
        GetRequest(apiUrl.getCommunityList, params, callback);
    }

    getOfficeList(callback: Function) {
        GetRequest(apiUrl.getOfficeList, {}, callback);
    }

    getCommunitySubUserPay(params: commonType.GetUserPayParams, callback: Function) {
        GetRequest(apiUrl.getCommunitySubUserPay, params, callback, false);
    }

    getSingleSubUserPay(params: commonType.GetUserPayParams, callback: Function) {
        GetRequest(apiUrl.getSingleSubUserPay, params, callback, false);
    }

    getPMUserChargePlan(params: commonType.GetUserPayParams, callback: Function) {
        GetRequest(apiUrl.getPMUserChargePlan, params, callback, false);
    }
}

const subscriptionApi = new SubscriptionApi();

export default subscriptionApi;
