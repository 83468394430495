import { PostRequest, successParam } from '@/api/base';
import { EditChargePlanType } from './all.type';

const apiUrl = {
    editOfficeChargePlan: 'v3/web/office/project/setOfficeChargeplan',
    editCommunityChargePlan: 'v3/web/community/communityData/editCommunityChargePlan'
};

class ProjectForSuperApi {
    editOfficeChargePlan(params: EditChargePlanType, callback: successParam) {
        PostRequest(apiUrl.editOfficeChargePlan, params, callback);
    }

    editCommunityChargePlan(params: EditChargePlanType, callback: successParam) {
        PostRequest(apiUrl.editCommunityChargePlan, params, callback);
    }
}

const projectForSuperApi = new ProjectForSuperApi();
export default projectForSuperApi;