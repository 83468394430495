import { GetRequest, PostRequest } from '@/api/base';
import { community } from '@/data';

const apiUrl = {
    getPmAllCommunity: 'v3/web/common/project/getAllForPm',
    getLocalAkManageAllForPm: 'v3/web/common/project/getLocalAkManageAllForPm',
    getPmCommunityData: 'v3/web/community/project/getStatData',
    getLastDoorLog: 'v3/web/community/capture/getLastDoorLog',
    getPmWeekData: 'v3/web/community/communityData/getWeekData',
    deleteCard: `v3/web/${community.ProjectType.value}/key/delRfCardLibrary`,
    deleteppincode: `v3/web/${community.ProjectType.value}/key/delPinLibrary`,
    delprrfcard: 'v3/web/community/key/delUserRfCardForOld',
    delpdrfcard: 'v3/web/community/key/delDeliverRfCardForOld',
    delpsrfcard: 'v3/web/community/key/delStaffRfCardForOld',
    delpdprikey: 'v3/web/community/key/delDeliverPinForOld',
    delprprikey: 'v3/web/community/key/delUserPinForOld'
};

class CommunityForPmApi {
    getPropertyCommunityList(callback: Function) {
        GetRequest(apiUrl.getPmAllCommunity, {}, callback);
    }

    getLocalAkManageAllForPm(callback: Function) {
        GetRequest(apiUrl.getLocalAkManageAllForPm, {}, callback);
    }

    getPMCommunityDetail(callback: Function) {
        const url = localStorage.getItem('Grade') === '23' ? 'v3/web/office/officeData/getDetailForPM' : 'v3/web/community/communityData/getDetailForPM';
        GetRequest(url, {}, callback);
    }

    getPmCommunityData(callback: Function) {
        GetRequest(apiUrl.getPmCommunityData, {}, callback);
    }

    getLastDoorLog(callback: Function) {
        GetRequest(apiUrl.getLastDoorLog, {}, callback);
    }

    getPmWeekData(callback: Function) {
        GetRequest(apiUrl.getPmWeekData, {}, callback);
    }

    deleteCard(params: object, callback: Function) {
        PostRequest(apiUrl.deleteCard, params, callback);
    }

    deleteppincode(params: object, callback: Function) {
        PostRequest(apiUrl.deleteppincode, params, callback);
    }

    delprrfcard(params: object, callback: Function) {
        PostRequest(apiUrl.delprrfcard, params, callback);
    }

    delpdrfcard(params: object, callback: Function) {
        PostRequest(apiUrl.delpdrfcard, params, callback);
    }

    delpsrfcard(params: object, callback: Function) {
        PostRequest(apiUrl.delpsrfcard, params, callback);
    }

    delpdprikey(params: object, callback: Function) {
        PostRequest(apiUrl.delpdprikey, params, callback);
    }

    delprprikey(params: object, callback: Function) {
        PostRequest(apiUrl.delprprikey, params, callback);
    }
}

const communityForPmApi = new CommunityForPmApi();
export default communityForPmApi;
