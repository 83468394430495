import { GetRequest, PostRequest, successParam } from '@/api/base';
import { defaultError } from '@/util/axios.config';

const apiUrl = {
    getSysConfig: 'v3/web/common/config/getSysConfig',
    getUnReadLogNum: 'v3/web/community/alarm/getUnReadLogNum',
    emergencyAction: 'v3/web/community/device/emergencyAction',
    getPmEmergencyDoorLog: 'v3/web/community/device/getPmEmergencyDoorLog',
    getRecentLog: 'v3/web/community/alarm/getRecentLog',
    setLogRead: 'v3/web/community/alarm/setLogRead',
    getPMInfo: 'v3/web/common/propertyManage/getInfo'
};

class CommonApi {
    getSysConfig(callback: Function) {
        GetRequest(apiUrl.getSysConfig, {}, callback);
    }

    // 获取未读警告数
    getUnReadLogNum(callback: Function) {
        GetRequest(apiUrl.getUnReadLogNum, {}, callback);
    }

    // 一键开门/关门
    emergencyAction(params: {Type: 0|1}, callback: successParam) {
        PostRequest(apiUrl.emergencyAction, params, callback, defaultError, true);
    }

    // PM一键开门后轮询获取设备开门记录
    getPmEmergencyDoorLog(params: {LogUUID: string}, callback: Function) {
        GetRequest(apiUrl.getPmEmergencyDoorLog, [params, false], callback);
    }

    // PM获取最近五分钟之内管理社区的告警信息
    getRecentLog(callback: Function, errorFunction: Function) {
        GetRequest(apiUrl.getRecentLog, [{}, false], callback, true, errorFunction);
    }

    // PM设置告警记录记录为已读
    setLogRead(params: {UUID: string}, callback: successParam) {
        PostRequest(apiUrl.setLogRead, [params, false], callback);
    }

    // 获取PM详细信息
    getPMInfo(callback: Function) {
        GetRequest(apiUrl.getPMInfo, [{}, false], callback);
    }
}

const commonApi = new CommonApi();
export default commonApi;
