import { GetRequest, PostRequest } from '@/api/base';
import { SearchParams } from '@/api/distributor/all.type';

const apiUrl = {
    getThirdPartyDeviceForProjectList: 'v3/web/community/thirdPartCamera/getListForDis',
    getThirdPartyDeviceForSingleList: 'v3/web/single/thirdPartCamera/getListForDis',
    deleteCommunityThirdPartCamera: 'v3/web/community/thirdPartCamera/delete',
    deleteSingleThirdPartCamera: 'v3/web/single/thirdPartCamera/delete'
};

class ThirdPartyDeviceApi {
    getThirdPartyDeviceForProjectList(params: SearchParams, callback: Function) {
        GetRequest(apiUrl.getThirdPartyDeviceForProjectList, params, callback);
    }

    getThirdPartyDeviceForSingleList(params: SearchParams, callback: Function) {
        GetRequest(apiUrl.getThirdPartyDeviceForSingleList, params, callback);
    }

    deleteCommunityThirdPartCamera(params: {UUID: string}, callback: Function) {
        PostRequest(apiUrl.deleteCommunityThirdPartCamera, params, callback);
    }

    deleteSingleThirdPartCamera(params: {UUID: string}, callback: Function) {
        PostRequest(apiUrl.deleteSingleThirdPartCamera, params, callback);
    }
}

const thirdPartyDeviceApi = new ThirdPartyDeviceApi();
export default thirdPartyDeviceApi;