import { PostRequest, successParam } from '@/api/base';
import { community } from '@/data';

const apiUrl = {
    addTempKey: `v3/web/${community.ProjectType.value}/tempKey/addPubTempKey`
};

class TempKeyForPMApi {
    addTempKey(params: object, callback: successParam) {
        PostRequest(apiUrl.addTempKey, params, callback);
    }
}

const tempKeyForPMApi = new TempKeyForPMApi();
export default tempKeyForPMApi;
