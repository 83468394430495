import { PostRequest, GetRequest, successParam } from '@/api/base';
import * as DeviceType from './all.type';

const apiUrl = {
    deleteDevice: 'v3/web/single/device/delete',
    thirdPartCameraGetListForIns: 'v3/web/single/thirdPartCamera/getListForIns',
    addThirdPartCamera: 'v3/web/single/thirdPartCamera/add',
    editThirdPartCamera: 'v3/web/single/thirdPartCamera/edit',
    thirdPartCameraGetLinkDeviceList: 'v3/web/single/thirdPartCamera/getLinkDeviceList',
    thirdPartCameraDelete: 'v3/web/single/thirdPartCamera/delete'
};

class DeviceApi {
    delete(params: DeviceType.Delete, callback: successParam) {
        PostRequest(apiUrl.deleteDevice, params, callback);
    }

    thirdPartCameraGetListForIns(
        params: object,
        callback: Function
    ) {
        GetRequest(apiUrl.thirdPartCameraGetListForIns, params, callback);
    }

    addThirdPartCamera(params: object, callback: successParam) {
        PostRequest(apiUrl.addThirdPartCamera, params, callback);
    }

    editThirdPartCamera(params: object, callback: successParam) {
        PostRequest(apiUrl.editThirdPartCamera, params, callback);
    }

    thirdPartCameraGetLinkDeviceList(params: object, callback: Function) {
        GetRequest(apiUrl.thirdPartCameraGetLinkDeviceList, params, callback);
    }

    thirdPartCameraDelete(params: object, callback: successParam) {
        PostRequest(apiUrl.thirdPartCameraDelete, params, callback);
    }
}

const deviceApi = new DeviceApi();
export default deviceApi;
