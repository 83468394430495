import { GetRequest, PostRequest, successParam } from '@/api/base';
import * as CommonType from './all.type';

const apiUrl = {
    login: 'v3/web/common/manage/login',
    getTokenStatus: '',
    getSmartHomeUri: 'v3/web/common/manage/getSmartHomeUri'
};

class LoginApi {
    login(params: CommonType.Login, callback: successParam, errorCallback: Function) {
        PostRequest(apiUrl.login, params, callback, errorCallback, false);
    }

    getTokenStatus(params: {
        account: string;
    }) {
        // GetRequest(apiUrl.getTokenStatus, [params, false], () => true);
    }

    getSmartHomeUri(callback: Function) {
        PostRequest(apiUrl.getSmartHomeUri, {}, callback, () => ({}), false);
    }
}

const loginApi = new LoginApi();
export default loginApi;
