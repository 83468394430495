import { GetRequest, PostRequest, successParam } from '@/api/base';

const apiUrl = {
    editThirdPartCamera: 'v3/web/community/thirdPartCamera/edit',
    getThirdPartyDevice: 'v3/web/community/thirdPartCamera/getListForPM'
};

class DeviceForPMApi {
    editThirdPartCamera(params: {Location: string;UUID: string}, callback: successParam) {
        PostRequest(apiUrl.editThirdPartCamera, params, callback);
    }

    getThirdPartyDevice(params: {Room: string;Build: string;Location: string}, callback: Function) {
        GetRequest(apiUrl.getThirdPartyDevice, params, callback);
    }
}

const deviceForPMApi = new DeviceForPMApi();
export default deviceForPMApi;