import { PostRequest } from '@/api/base';
import { DeletePmParams } from './all.type';

const apiUrl = {
    deletePm: 'v3/web/common/account/delComPManage'
};

class HomeApi {
    deletePm(params: DeletePmParams, callback: Function) {
        PostRequest(apiUrl.deletePm, params, callback);
    }
}

const homeApi = new HomeApi();
export default homeApi;