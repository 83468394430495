import { PostRequest, successParam } from '@/api/base';

const apiUrl = {
    addDepartment: 'v3/web/office/department/add',
    editDepartment: 'v3/web/office/department/edit',
    delDepartment: 'v3/web/office/department/delete'
};

class PeopleDeviceApi {
    addDepartment(params: object, callback: successParam) {
        PostRequest(apiUrl.addDepartment, params, callback);
    }

    editDepartment(params: object, callback: successParam) {
        PostRequest(apiUrl.editDepartment, params, callback);
    }

    delDepartment(params: object, callback: successParam) {
        PostRequest(apiUrl.delDepartment, params, callback);
    }
}

const peopleDeviceApi = new PeopleDeviceApi();
export default peopleDeviceApi;
