import { PostRequest, successParam } from '@/api/base';

const apiUrl = {
    deleteAllRfCard: 'v3/web/community/key/delStaffAllRfCardForOld',
    importRfCard: 'v3/web/community/key/importStaffRfCardForOld',
    addStaffRfCard: 'v3/web/community/key/addStaffRfCardForOld',
    editStaffRfCard: 'v3/web/community/key/editStaffRfCardForOld'
};
class StaffAuthApi {
    deleteAllRfCard(params: {PassWd: string;Type: string}, callback: successParam) {
        PostRequest(apiUrl.deleteAllRfCard, params, callback);
    }

    importRfCard(params: object, callback: successParam) {
        PostRequest(apiUrl.importRfCard, params, callback);
    }

    addStaffRfCard(params: object, callback: successParam) {
        PostRequest(apiUrl.addStaffRfCard, params, callback);
    }

    editStaffRfCard(params: object, callback: successParam) {
        PostRequest(apiUrl.editStaffRfCard, params, callback);
    }
}

const staffAuthApi = new StaffAuthApi();
export default staffAuthApi;
