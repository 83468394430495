import { PostRequest, successParam } from '@/api/base';

const apiUrl = {
    addDeliveryPin: 'v3/web/community/delivery/addDeliverPinForOld',
    addDeliveryRfCard: 'v3/web/community/delivery/addDeliverRfCardForOld',
    editDeliveryPin: 'v3/web/community/key/editDeliverPinForOld',
    editDeliveryRfCard: 'v3/web/community/key/editDeliverRfCardForOld'
};

class DeliveryAuthApi {
    addDeliveryPin(params: object, callback: successParam) {
        PostRequest(apiUrl.addDeliveryPin, params, callback);
    }

    editDeliveryPin(params: object, callback: successParam) {
        PostRequest(apiUrl.editDeliveryPin, params, callback);
    }

    addDeliveryRfCard(params: object, callback: successParam) {
        PostRequest(apiUrl.addDeliveryRfCard, params, callback);
    }

    editDeliveryRfCard(params: object, callback: successParam) {
        PostRequest(apiUrl.editDeliveryRfCard, params, callback);
    }
}

const deliveryAuthApi = new DeliveryAuthApi();

export default deliveryAuthApi;
