import { PostRequest, GetRequest, successParam } from '@/api/base';
import { community } from '@/data';
import * as pmType from './all.type';

const apiUrl = {
    addNewMainUserForPM: 'v3/web/community/mainUser/addForPm',
    addNewSubUserForPM: 'v3/web/community/subUser/addForPm',
    addOfficeUserForPM: 'v3/web/office/user/addOfficeUser',
    addMainUserCheck: 'v3/web/community/mainUser/addCheckForPm',
    addSubUserCheck: 'v3/web/community/subUser/addCheckForPm',
    editUserInfo: 'v3/web/community/user/editForPm',
    editOfficeUserInfo: 'v3/web/office/user/modOfficeUser',
    addMainForOldPm: 'v3/web/community/mainUser/addForOldPm',
    editMainForOldPm: 'v3/web/community/mainUser/editForOldPm',
    getUserInfoOldPm: 'v3/web/community/user/getInfoByPm',
    addSubForOldPm: 'v3/web/community/subUser/addForOldPm',
    editSubForOldPm: 'v3/web/community/subUser/editForOldPm',
    resetUserPassword: `v3/web/${community.ProjectType.value}/user/resetPwd`,
    resetSubUserPassword: 'v3/web/community/user/resetPwd'
};

class ResidentApi {
    addNewMainUserForPM(params: object, callback: successParam) {
        PostRequest(apiUrl.addNewMainUserForPM, params, callback);
    }

    addNewSubUserForPM(params: object, callback: successParam) {
        PostRequest(apiUrl.addNewSubUserForPM, params, callback);
    }

    addOfficeUserForPM(params: object, callback: successParam) {
        PostRequest(apiUrl.addOfficeUserForPM, params, callback);
    }

    addMainUserCheck(params: object, callback: successParam) {
        PostRequest(apiUrl.addMainUserCheck, params, callback);
    }

    addSubUserCheck(params: object, callback: successParam) {
        PostRequest(apiUrl.addSubUserCheck, params, callback);
    }

    editUserInfo(params: object, callback: successParam) {
        PostRequest(apiUrl.editUserInfo, params, callback);
    }

    editOfficeUserInfo(params: object, callback: successParam) {
        PostRequest(apiUrl.editOfficeUserInfo, params, callback);
    }

    addForOldPm(params: object, callback: successParam) {
        PostRequest(apiUrl.addMainForOldPm, params, callback);
    }

    editForOldPm(params: object, callback: successParam) {
        PostRequest(apiUrl.editMainForOldPm, params, callback);
    }

    getUserInfoOldPm(params: object, callback: Function) {
        GetRequest(apiUrl.getUserInfoOldPm, params, callback);
    }

    addSubForOldPm(params: object, callback: successParam) {
        PostRequest(apiUrl.addSubForOldPm, params, callback);
    }

    editSubForOldPm(params: object, callback: successParam) {
        PostRequest(apiUrl.editSubForOldPm, params, callback);
    }

    resetUserPassword(params: object, callback: successParam) {
        PostRequest(apiUrl.resetUserPassword, params, callback);
    }

    resetSubUserPassword(params: object, callback: successParam) {
        PostRequest(apiUrl.resetSubUserPassword, params, callback);
    }

    setDealImportTask(params: object, callback: successParam) {
        PostRequest('v3/web/community/importTask/setDeal', params, callback);
    }

    editAccessFloor(params: object, callback: successParam) {
        PostRequest('v3/web/office/user/editAccessFloor', params, callback);
    }

    editComAccessFloor(params: object, callback: successParam) {
        PostRequest('v3/web/community/user/editAccessFloor', params, callback);
    }
}

const residentApi = new ResidentApi();

export default residentApi;
